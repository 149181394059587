import { apiRequests } from '../utilities/axios-factory'
import store from '../store'

function getTransportationEquipment ({ id, filter }) {
  return apiRequests.get('/v1/transportation-equipment/' + id + '/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTransportationEquipment (filter) {
  return apiRequests.get('/v1/transportation-equipment/join/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTotalTransportationEquipment ({ where, join }) {
  return apiRequests.get('/v1/transportation-equipment/count/join/', {
    params: { where, join },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function registerTransportationEquipment ({ id, inventoryId, deviceId, accessories, osVersion, appVersion, transportationOwnerId, transportationProfileId, hotspotSsid, hotspotPassword }) {
  return apiRequests.post('/v1/transportation-equipment/registration/', { id, inventoryId, deviceId, accessories, osVersion, appVersion, transportationOwnerId, transportationProfileId, hotspotSsid, hotspotPassword }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function disconnectTransportationEquipment ({ transportationEquipmentId, transportationOwnerId }) {
  return apiRequests.put('/v1/transportation-equipment/disconnect/', { transportationEquipmentId, transportationOwnerId }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

export {
  getTransportationEquipment,
  findTransportationEquipment,
  findTotalTransportationEquipment,
  registerTransportationEquipment,
  disconnectTransportationEquipment
}
