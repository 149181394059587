import { apiRequests, mapApiRequests } from '../utilities/axios-factory'
import store from '../store'
import { sleep } from '@smarttransit/common'

function getStRoute ({ id, include = 'routeFares' }) {
  const params = include ? { filter: { include } } : null

  return apiRequests.get(`/v1/st-route/` + id + '/', {
    params,
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

async function searchOtpRoutes ({ routeId, routeParams, timeoutMs = 15000 }) {
  const result = await mapApiRequests.post(`/v1/geo-location/routes/${routeId}/`, { routeParams }, {
    headers: { 'Authorization': store.getters.getTokenId }
  })

  const referenceId = result?.data?.id

  if (!referenceId) {
    throw new Error('No referenceId returned in OTP route search request')
  }

  let searchResults = null
  const pollingStartTime = Date.now()

  do {
    searchResults = await mapApiRequests.get(`/v1/geo-location/routes/${referenceId}/`, {
      headers: { 'Authorization': store.getters.getTokenId }
    })

    if (searchResults?.data?.pending && Date.now() - pollingStartTime > timeoutMs) {
      throw new Error('OTP route search request timed out')
    }

    await sleep(800)
  } while (searchResults?.data?.pending)

  return searchResults?.data
}

function getStRouteTemplate () {
  return {
    label: '',
    agencyId: null,
    routeIdList: null
  }
}

function createStRoute ({ routeIdList, routeLine, agencyId, label }) {
  return apiRequests.post(`/v1/st-route/`, { routeIdList, routeLine, agencyId, label }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function updateStRoute ({ id, routeIdList, routeLine, agencyId, label, routeFares }) {
  return apiRequests.patch(`/v1/st-route/${id}/`, { routeIdList, routeLine, agencyId, label, routeFares }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findStRoutes ({ keywords, routeIdList, agencyId, dateFrom, dateTo, offset, limit, order, include }) {
  return apiRequests.get(`/v1/st-route/`, {
    params: { keywords, routeIdList, agencyId, dateFrom, dateTo, limit, include, offset, order },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTotalStRoutes ({ keywords, routeIdList, agencyId, dateFrom, dateTo }) {
  return apiRequests.get(`/v1/st-route/count/`, {
    params: { keywords, routeIdList, agencyId, dateFrom, dateTo },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function deleteStRoute (id) {
  return apiRequests.delete('/v1/st-route/' + id + '/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function startGeneratePermutations (stRouteId) {
  return apiRequests.post(`/v1/st-route/${stRouteId}/permutations/`, {}, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function stopGeneratePermutations (stRouteId) {
  return apiRequests.delete(`/v1/st-route/${stRouteId}/permutations/`, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function getPermutationsJob (stRouteId) {
  return apiRequests.get(`/v1/st-route/${stRouteId}/permutations/`, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function getRouteFaresByRouteId (stRouteId) {
  return apiRequests.get(`/v1/st-route/${stRouteId}/route-fares/`, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

export {
  getStRoute,
  getStRouteTemplate,
  createStRoute,
  updateStRoute,
  deleteStRoute,
  findStRoutes,
  findTotalStRoutes,
  startGeneratePermutations,
  stopGeneratePermutations,
  getPermutationsJob,
  getRouteFaresByRouteId,
  searchOtpRoutes
}
